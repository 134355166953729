import { Svg } from 'assets';
import eventImage from 'assets/images/img/eventImage.png';
import orgImage from 'assets/images/img/footer.png';
import businessImage from 'assets/images/img/banner.png';

export const AboutBusinessList = [
    {
        icon: <Svg.EventsNearSvg />,
        title: 'Discover Exciting Events Near You',
        subTitle: 'Find and attend upcoming events within the Armenian community.',
        link: '/events',
    },
    {
        icon: <Svg.ArmenianBusinessesSvg />,
        title: 'Explore Local Armenian Businesses',
        subTitle: 'Discover and connect with Armenian-owned businesses in your area.',
        link: '/businesses',
    },
    {
        icon: <Svg.ArmenianOrganizationsSvg />,
        title: 'Support Armenian Nonprofits',
        subTitle: 'Learn about and support nonprofit organizations serving the Armenian community.',
        link: '/nonprofits',
    },
];

export const TabList = [
    {
        label: 'All',
        value: 'ALL',
    },
    {
        label: 'Art&Culture',
        value: 'ART',
    },
    {
        label: 'Expos',
        value: 'EXPOS',
    },
    {
        label: 'Music',
        value: 'MUSIC',
    },
];

export const DiscoveredList = [
    {
        label: 'View All',
        value: 'View All',
    },
    {
        label: 'Food',
        value: 'ART',
    },
    {
        label: 'Technology',
        value: 'EXPOS',
    },
    {
        label: 'Art',
        value: 'MUSIC',
    },
];

export const TagLists = [
    {
        id: 1,
        Icon: '🍿 ',
        title: 'Cinema',
    },

    {
        id: 2,
        Icon: '📜 ',
        title: 'Education',
    },

    {
        id: 3,
        Icon: '👜 ',
        title: 'Fashion',
    },

    {
        id: 4,
        Icon: '',
        title: 'tag 1',
    },

    {
        id: 5,
        Icon: '',
        title: 'tag 2',
    },

    {
        id: 6,
        Icon: '💃🏻 ',
        title: 'Dance',
    },
    {
        id: 7,
        Icon: '',
        title: 'tag 3',
    },

    {
        id: 8,
        Icon: '',
        title: 'tag 4',
    },

    {
        id: 9,
        Icon: '',
        title: 'tag 5',
    },

    {
        id: 10,
        Icon: '',
        title: 'tag 6',
    },

    {
        id: 11,
        Icon: '',
        title: 'tag 7',
    },
];

export const benefitsList = [
    {
        title: 'Benefit 1',
        description: 'Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.',
    },
    {
        title: 'Benefit 2',
        description: 'Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.',
    },
    {
        title: 'Benefit 3',
        description: 'Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.',
    },
    {
        title: 'Benefit 4',
        description: 'Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.',
    },
];

