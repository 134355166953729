import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { Svg } from 'assets';
import { mapStyles } from 'fragments';
import { PhoneRow, TextRow } from 'components';
import { useWindowDimensions } from 'utils';

const OrganizationMapView = (props) => {
    const { organizations } = useSelector((state) => state.orgs);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [org, setOrg] = useState(null);
    const navigate = useNavigate();
    const orgImageUrl = org?.images?.length
        ? org.images[org.mainImage ? org.mainImage : 0].url
        : org?.avatar?.url
            ? org?.avatar?.url
            : null;
    const { width } = useWindowDimensions();

    const mapStyle = {
        width: '100%',
        height: width > 768 ? '1000px' : '70vh',
        position: 'relative',
    };

    const onMarkerClick = (props, marker, item) => {
        setOrg(item);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const onClose = () => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };

    return (
        <div className='org-map-view-section'>
            <Map
                google={props.google}
                zoom={12}
                styles={mapStyles}
                style={mapStyle}
                initialCenter={{
                    lat: 40.712776,
                    lng: -74.005974,
                }}
                containerStyle={mapStyle}
                streetViewControl={false}
                fullscreenControl={false}
                mapTypeControl={false}
                onClick={onClose}
            >
                {organizations?.orgs?.map(
                    (i) =>
                        i?.address?.lat && (
                            <Marker
                                onClick={(e, j) => onMarkerClick(e, j, i)}
                                position={{
                                    lat: i.address.lat,
                                    lng: i.address.lng,
                                }}
                                icon={Svg.MapMarker}
                                key={i?.id}
                            />
                        ),
                )}
                <InfoWindow marker={activeMarker} visible={showInfoWindow} onClose={onClose}>
                    <div className='org-map-card' onClick={() => navigate(`/organization/${org?.id}`)}>
                        <div className='org-map-image-and-title'>
                            <div className='org-map-image-box'>{orgImageUrl ? <img src={orgImageUrl} alt='icon' /> :
                                <Svg.Gallery />}</div>
                            <div>
                                <div className='org-map-title'>
                                    <TextRow name={org?.name} />
                                </div>
                                {org?.address?.formattedAddress && (
                                    <div className='org-map-info-box'>
                                        <a
                                            className='address-link'
                                            target={'_blank'}
                                            href={`https://www.google.com/maps?saddr=My+Location&daddr=${org?.address?.lat},${org?.address?.lng}`}
                                            rel='noreferrer'
                                        >
                                            {org?.address?.formattedAddress}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>

                        {(org?.phoneNumber || org?.email) &&
                            <div className='org-map-phone-and-email'>
                                {org?.phoneNumber && (
                                    <div className='org-map-info-box' style={{ marginBottom: '8px' }}>
                                        <Svg.CallCalling />
                                        <a href={`tel:+${org?.phoneNumber}`}>
                                            <PhoneRow phone={org?.phoneNumber} />
                                        </a>
                                    </div>
                                )}

                                {org?.email && (
                                    <div className='org-map-info-box'>
                                        <Svg.Sms />
                                        <a href={`mailto:${org?.email}`}>{org?.email}</a>
                                    </div>
                                )}
                            </div>
                        }

                        <a href={`/organization/${org?.id}`} target='_blank' rel='noopener' className='org-map-card-mobile-button'>View details</a>
                    </div>
                </InfoWindow>
            </Map>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(OrganizationMapView);
