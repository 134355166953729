import * as React from 'react';
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { SearchedItems } from './searchedItems';
import { textForShow } from './constants';
import { PaginationItem } from 'components';
import { useWindowSize } from 'utils';
import { useState } from 'react';
import { useLocation } from 'react-router';

export default function SearchedDrawer() {
    const { searchResults } = useSelector((state) => ({
        searchResults: state.auth.searchResults,
    }));
    const [state, setState] = useState({ bottom: false });
    const [width] = useWindowSize();
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const searchField = searchParams.get('searchField');
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const address = searchParams.get('address');
    const renderText = textForShow(searchField, lat, lng, address);
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div className="search-page">
            <div
                className="bottom-anchor-list"
                style={{ height: '70vh' }}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}>
                <div>
                    <div className="discovered-swipe-box">
                        <div />
                        <p>{renderText === 'noTypedText' ? `Search Results` : `Search Result for ‘’${renderText}’’`}</p>
                    </div>

                    <div className="discover-organizations-box">
                        {searchResults?.results?.map((item, j) => (
                            <React.Fragment key={j}>
                                <SearchedItems item={item} boxShadow={width < 768} />
                            </React.Fragment>
                        ))}

                    </div>

                    <div className="discover-organizations-pagination">
                        <PaginationItem count={searchResults?.count} link={`${window.location.pathname}${window.location.search}`} />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="bottom-anchor-box">
            <React.Fragment>
                <div style={{ cursor: 'pointer' }} className="discovered-swipe-box" onClick={toggleDrawer('bottom', true)}>
                    <div />
                    <p>Search Results</p>
                </div>
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}>
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
