import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Routers } from './root';
import { Layout, Footer, ResetPassword } from './fragments';
import { ToastContainer } from 'react-toastify';
import { useModal } from './utils';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'swiper/css/bundle';



function App() {
    const location = useLocation()
    const query = new URLSearchParams(location?.search);
    const [resetToken, setResetToken] = useState('');
    const resetTokenQuery = query.get('resetToken');
    const navigate = useNavigate();
    const { openModal } = useModal();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location?.pathname]);

    useEffect(() => {
        if (resetToken) {
            openModal(<ResetPassword token={resetToken} />);
        }
    }, [resetToken]);

    useEffect(() => {
        if (resetTokenQuery) {
            setResetToken(resetTokenQuery);
            navigate('/');
        }
    }, [resetTokenQuery]);

    return (
        <div>
            <div className="app-box">
                <Layout />
                <Routers />
                <Footer />
            </div>
            <ToastContainer position="bottom-right" />
        </div>
    );
}

export default App;
