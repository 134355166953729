import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LogIn, TagsCards } from 'fragments';
import { benefitsList, TagLists } from './constatnts';
import { token, useModal, userInfo } from 'utils';
import { Svg } from 'assets';
import { authActions } from 'store';

export const Benefits = () => {
    const dispatch = useDispatch();
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));
    const user = myProfile || userInfo;
    const [selectedTags, setSelectedTags] = useState([]);
    const navigate = useNavigate();
    const { openModal } = useModal();

    useEffect(() => {
        if (user?.prefCategories && !selectedTags.length) {
            setSelectedTags(user?.prefCategories || []);
        }
    }, [user]);

    const handleAddCategory = (cat) => {
        if (token) {
            const params = {};
            if (selectedTags?.includes(cat)) {
                const newList = selectedTags.filter((i) => i !== cat);
                setSelectedTags(newList);
                params.prefCategories = newList;
            } else {
                params.prefCategories = [...selectedTags, cat];
                setSelectedTags([...selectedTags, cat]);
            }
            dispatch(authActions.editAccount(params));
        } else {
            openModal(<LogIn />);
        }
    };

    return (
        <div className='benefits-section'>
            <div className='container'>
                {/*<div className='personalized-section-box'>*/}
                {/*    <p className='personalized-section-title'>Personalized Recommendations Just for You</p>*/}
                {/*    <p className='personalized-section-desc'>*/}
                {/*        Customize Your Armat Experience by Selecting Your Interests*/}
                {/*    </p>*/}

                {/*    <div className='personalized-tags-show-button'>*/}
                {/*        <TagsCards*/}
                {/*            tagsList={TagLists}*/}
                {/*            selectedTags={selectedTags}*/}
                {/*            handleAddCategory={handleAddCategory}*/}
                {/*            allowShowButton={true}*/}
                {/*            onClickShowButton={() => navigate('/recommendations')}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className='benefits-section-box'>
                    <div className='benefit-titles-svg_box'>
                        <div className='benefit-title-dec-box'>
                            <div className='benefit-title-svg-block'>
                                <p className='benefit-title'>Create Your Organization in Armat</p>
                                <Svg.BenefitSvg className='benefit-svg-mobile' />
                            </div>

                            <p className='benefit-description'>
                                By establishing an organization, you'll have the opportunity to
                                become an integral part of the Armenian community, connect with like-minded individuals,
                                and contribute to meaningful initiatives.
                            </p>
                        </div>

                        {/*<Svg.BenefitSvg className='benefit-svg' />*/}
                    </div>

                    <div className='benefit-cards-wrapper'>
                        {benefitsList?.map(({ title, description }) => (
                            <div className='benefit-cards-box'>
                                <div>
                                    <p className='benefit-card-title'>{title}</p>
                                    <p className='benefit-card-desc'>
                                        {description}
                                    </p>
                                </div>

                                {/*<Svg.BenefitCardIcon className='benefit-card-svg' />*/}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
